import React, {useState} from 'react';
import {Formik} from 'formik';
import {
  Form,
  FormErrorSummary,
  SignUpMutation
} from "@deity/falcon-ecommerce-uikit";
import {FormField, FormFieldPassword} from "../SignIn/FormField";
import {Button, ButtonTypes} from "benlux-ecommerce-ui";
import * as Yup from 'yup';


export const SignUpForm = ({onCompleted, initialEmail = ''}) => {
  const [fidelityForm, setFidelityForm] = useState(false);
  const [passwordCheck, setPasswordCheck] = useState(false);


  const changeForm = () => {
    if (fidelityForm) {
      setFidelityForm(false)
    } else {
      setFidelityForm(true)
    }
  }

  const SignupSchema = Yup.object().shape({
    firstname: Yup.string()
      .min(2, 'Le prénom est trop court.')
      .max(50, 'Le prénom est trop long.')
      .required('Un prénom est obligatoire.'),
    lastname: Yup.string()
      .min(2, 'Le nom est trop court.')
      .max(50, 'Le nom est trop long.')
      .required('Un nom est obligatoire.'),
    email: Yup.string().email('L\'adresse e-mail n\'est pas valide.').required('Une adresse e-mail est obligatoire.'),
    password: Yup.string().required("Un mot de passe est obligatoire."),
  });
  return (
    <SignUpMutation onCompleted={onCompleted}>
      {(signUp, {loading, error}) => {
        return (
        <Formik
          initialValues={
            {
              firstname: '',
              lastname: '',
              email: initialEmail,
              password: ''
            }
          }
          validationSchema={SignupSchema}
          onSubmit={(values) => {
            signUp({variables: {input: {...values, autoSignIn: true}}});
          }}
        >
          {({errors, touched}) => (
            <Form id="sign-up" i18nId="signUp">
              {/*<div className={"fidelity"}>
                <p>Je n’ai pas de carte fidélité Benlux</p>
                <label className="switch">
                  <input type="checkbox"/>
                  <span className="slider round"/>
                </label>
                <button onClick={() => changeForm()} className={fidelityForm ? "buttonTrue" : "buttonFalse"}>test</button>
              </div>*/}
              <div className={"signUpBlockForm"}>
                {!fidelityForm ? (
                  <>
                    <FormField
                      name="firstname"
                      type="text"
                      errorMessage={touched.firstname && errors.firstname && errors.firstname}
                      autoComplete="given-name"
                      placeholder={"Prénom"}
                    />
                    <FormField
                      name="lastname"
                      type="text"
                      errorMessage={touched.lastname && errors.lastname && errors.lastname}
                      autoComplete="family-name"
                      placeholder={"Nom"}
                    />
                  </>
                ) : (
                  <div style={{display: 'flex'}}>
                    <FormField name="card fidelity" type="text" required
                               autoComplete="given-name"
                               placeholder={"Numéro de carte de fidélité"}/>
                    <span>Le numéro se trouve au dos de votre carte fidélité BENLUX et commence par B00</span>
                  </div>
                )
                }
                <FormField
                  name="email"
                  type="email"
                  autoComplete="email"
                  errorMessage={touched.email && errors.email && errors.email}
                  placeholder={"E-mail"}
                />
                <FormFieldPassword
                  name="password"
                  required
                  type="password"
                  autoComplete="new-password"
                  errorMessage={touched.password && errors.password && errors.password}
                  placeholder={"Mot de passe"}
                  validPassword={(v) => setPasswordCheck(v)}
                  passwordValidator={true}
                />

              </div>
              <div className={"createButton"}>
                <Button type={ButtonTypes.Secondary} as={'submit'}
                        text={'Créer mon compte'} loading={loading}/>
                <p>En cliquant sur Créer mon compte, j’accepte les termes et
                  conditions & politique de confidentialité</p>
              </div>
              {error &&
                <div style={{textAlign: 'center'}}>
                  <FormErrorSummary errors={error.graphQLErrors.map(e => e.message)}/>
                </div>
              }
            </Form>
          )}
        </Formik>
        )
      }}
    </SignUpMutation>
  )
};


export const ToSignUpForm = ({onCompleted, initialEmail = ''}) => {


  return (

        <Formik
          initialValues={
            {
              email: '',
            }
          }
          onSubmit={(values) => onCompleted && onCompleted(values)}
        >
          {() => (
            <Form id="sign-up" i18nId="signUp">
              <div className={"toSignUpBlockForm"}>
                <FormField
                  name="email" type="email"
                  required
                  autoComplete="email"
                  errorMessage={"L'adresse e-mail est obligatoire."}
                  placeholder={"E-mail"}
                />
                <div className={"rightObject"}>
                  <Button
                    type={ButtonTypes.Secondary}
                    as={'submit'}
                    text={'Créer mon compte'}
                  />
                </div>
              </div>
              {/*<FormErrorSummary errors={error && [error.message]}/>*/}
            </Form>
          )}
        </Formik>
  )
};
