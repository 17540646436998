import React from 'react';
import {SignUpForm} from "./SignUpForm";
import "./SignUp.css";

const SignUp = ({history, location}) => {
    const {search, state} = location;
    const queryParams = new URLSearchParams(search);
    const next = queryParams.get('next') || '/compte';

    return (
        <div className={"wrapperUp"}>
            <div className={"signUpWrapper"}>
                <h1>S'inscrire sur Benlux</h1>
                <SignUpForm
                  onCompleted={() => history.replace(next)}
                  initialEmail={state ? state.email : null}
                />
            </div>
        </div>
    )
};

export default SignUp;
